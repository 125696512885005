import { ContentTypeEnum } from '@/enums/http-enum'

/**
 * 分页查询联动配置记录
 */
export const queryLinkageConfigsApi = (param: any) => useGet('/deploy/linkage/page', param)

/**
 * 查询联动配置详情
 */
export const getLinkageConfigDetailByIdApi = (id: number) => useGet(`/deploy/linkage/${id}`)

/**
 * 修改联动配置详情
 */
export const updateLinkageConfigDetailApi = (data: any) => usePost('/deploy/linkage/detail', data)

/**
 * 新增联动配置
 */
export const addLinkageConfigApi = (data: any) => usePost('/deploy/linkage', data)

/**
 * 修改联动配置
 */
export const updateLinkageConfigApi = (data: any) => usePut('/deploy/linkage', data)

/**
 * 删除联动配置
 */
export const deleteLinkageConfigApi = (id: number) => useDelete<boolean>(`/engine/linkage/${id}`)

/**
 * 预测cron执行时间
 */
export const getCronNextTimeApi = (params: any) => useGet('/deploy/linkage/cron-next-time', params)

/**
 * 分页查询联动记录
 */
export const queryLinkageRecordsApi = (param: any) => useGet('/runtime/linkage-record/page', param)

/**
 * 启用/禁用联动配置
 */
export const enableLinkageApi = (data: any) => usePut<boolean>('/engine/linkage/enable', data, { headers: { 'Content-Type': ContentTypeEnum.FORM_URLENCODED } })
